import { motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import useStore from "../../gameStore";
import boomerImg from "../../images/boomer.svg";
import cardImage from "../../images/card_game.jpg";
import genXImg from "../../images/genx.svg";
import genZImg from "../../images/genz.svg";
import millennialImg from "../../images/millennial.svg";
import packBlack from "../../images/pack_black.png";
import packGreen from "../../images/pack_green.png";
import packOrange from "../../images/pack_orange.png";
import packPurple from "../../images/pack_purple.png";
import packRed from "../../images/pack_red.png";
import packWhite from "../../images/pack_white.png";
import packYellow from "../../images/pack_yellow.png";
import { containerVariants } from "../../motionVariants";
import Loading from "../Loading";
import SecondaryButton from "../SecondaryButton";
import HostExpansionCard, { Pack } from "./HostExpansionCard";
import HostExpansionMenu from "./HostExpansionMenu";
import HostStartHeader from "./HostStartHeader";
import "./HostStartScreen.css";

// TODO: this should be defined one place for here and GameMenu
export const packSlides: Pack[] = [
  {
    id: "",
    name: "Premium",
    image: packWhite,
    title: "Pop Culture",
    status: "free",
    description:
      "It's like TikTok & Jeopardy had a love child. Play the party trivia game with strategy needed to win, hints written by comedians, and questions for every generation. \n\nEnjoy hours of laughter, nostalgia, stories, and surprise with a new twist on trivia that levels the playing field.",
  },
  {
    id: "",
    name: "foodfight",
    image: packOrange,
    title: "Food Fight!",
    status: "free",
    description:
      "Satisfy your cravings for trivia with our fun and delicious food-themed game. Test your knowledge and impress your friends with your culinary pop culture expertise!",
    color: "#FF9300",
  },
  {
    id: "",
    name: "tv-movies",
    image: packRed,
    title: "TV & Movies",
    status: "free",
    description:
      "Lights, Camera, Trivia! Unleash your inner couch potato and embark on a cinematic journey through the ages with our brand new TV & Movies trivia pack. Test your motion picture expertise, share some laughs, and along the way, learn how much your friends actually Netflix and chill.",
  },
  {
    id: "",
    name: "superheroes",
    image: packYellow,
    title: "Superheroes & Villains",
    status: "coming soon",
    description: "",
  },
  {
    id: "",
    name: "womeninsports",
    image: packGreen,
    title: "Women in Sports",
    status: "coming soon",
    description: "Women in sports trivia",
  },
  {
    id: "",
    name: "f1",
    image: packPurple,
    title: "F1",
    status: "coming soon",
    description: "",
  },
  {
    id: "",
    name: "afterdark",
    image: packBlack,
    title: "After Dark",
    status: "coming soon",
    invert: true,
    description: "",
  },
  {
    id: "",
    name: "horror",
    image: packRed,
    title: "Horror",
    status: "coming soon",
    description: "",
  },
  {
    id: "",
    name: "truecrime",
    image: packYellow,
    title: "True Crime",
    status: "coming soon",
    description: "",
  },
];

const imgProps = [
  // { src: greatestImg, alt: "greatest" },
  { src: boomerImg, alt: "boomer" },
  { src: genXImg, alt: "genX" },
  { src: millennialImg, alt: "millennial" },
  { src: genZImg, alt: "genZ" },
  // { src: smileImg, alt: "smile" },
];
const imgPositions = [
  // { top: 20, left: 90 },
  // { top: 30, left: 70 },
  { top: 50, left: 60 },
  { top: 75, left: 30 },
  { top: 85, left: 40 },
  { top: 90, left: 10 },
];
const shuffledImgs = imgProps.sort((a, b) => Math.random() - 0.5);
const stickerVariants = Array(imgProps.length)
  .fill("")
  .map(() => ({
    hidden: { scale: 4, rotate: Math.random() * 60 - 30, opacity: 0 },
    show: { scale: 1, rotate: Math.random() * 60 - 30, opacity: 1 },
  }));
const HostStartScreen = () => {
  const { questionSets, previewQuestionSet, setPreviewQuestionSet } =
    useStore();
  if (!questionSets) {
    return <Loading />;
  } else {
    return (
      <>
        {previewQuestionSet === null ? (
          <>
            <HostStartHeader />
            <div className="StartScreen-packs">
              {packSlides.map((pack, i) => (
                <HostExpansionCard
                  key={i}
                  pack={pack}
                  onClick={() => setPreviewQuestionSet(i)}
                />
              ))}
            </div>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              className="StartScreen-background"
            >
              {shuffledImgs.map((imgProp, index) => (
                <motion.img
                  key={index}
                  className="StartScreen-sticker"
                  {...imgProp}
                  alt={imgProp.alt}
                  variants={stickerVariants[index]}
                  style={{
                    top: `${imgPositions[index].top}%`,
                    left: `${imgPositions[index].left}%`,
                  }}
                />
              ))}
            </motion.div>
            <div className="StartScreen-card">
              <img src={cardImage} alt="card game" />
              <h3>
                Get the <br />
                card game
              </h3>
              <h3 className="discount">
                Sale on <br />
                Amazon
              </h3>
              <QRCodeSVG
                value="https://www.amazon.com/GENSMAK-Party-Trivia-Every-Generation/dp/B0BMB5PWNT"
                className="qrcode"
                fgColor="#1c5fa0"
                bgColor="#fff"
              />
            </div>
          </>
        ) : (
          <>
            <HostExpansionMenu pack={packSlides[previewQuestionSet]}>
              <SecondaryButton onClick={() => setPreviewQuestionSet(null)}>
                Close
              </SecondaryButton>
            </HostExpansionMenu>
          </>
        )}
      </>
    );
  }
};

export default HostStartScreen;
