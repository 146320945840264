import { motion } from "framer-motion";
import useStore from "../gameStore";
import restartIcon from "../images/restart.svg";
import skipIcon from "../images/skip.svg";
import { itemVariants } from "../motionVariants";
import { numSlides, useSlideController } from "../slideController";
import "./InstructionButtons.css";
import MainButton from "./MainButton";

const InstructionButtons = ({
  className,
  style: styleOverride,
}: {
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { slideNumber } = useStore();
  const { startSlides, skipSlide, endSlides } = useSlideController();
  return (
    <motion.div
      variants={itemVariants}
      transition={{ type: "spring" }}
      initial={"hidden"}
      animate={"show"}
      className={`InstructionButtons ${className || ""}`}
      style={styleOverride}
    >
      <MainButton onClick={startSlides}>
        <img src={restartIcon} alt="restart" />
        Restart
      </MainButton>
      {slideNumber < numSlides - 1 ? (
        <MainButton onClick={skipSlide} autoFocus>
          <img src={skipIcon} alt="skip" />
          Skip
        </MainButton>
      ) : (
        <MainButton
          onClick={endSlides}
          className="InstructionButtons-start"
          autoFocus
        >
          Start
        </MainButton>
      )}
    </motion.div>
  );
};

export default InstructionButtons;
