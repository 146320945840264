import { useEffect } from "react";

export const useArrowKeyNav = (enabled = true) => {
  useEffect(() => {
    if (!enabled) return;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "ArrowUp" ||
        e.key === "ArrowDown" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        console.log("ARROW KEY PRESSED");
        const buttons = Array.from(
          document.querySelectorAll(
            "button:not([disabled]), a:not([disabled]), input:not([disabled]), label"
          )
        ) as HTMLElement[];
        const focused = document.activeElement as HTMLElement;
        // if nothing was focused, focus the first button
        if (buttons.length > 0 && (!focused || !buttons.includes(focused))) {
          buttons[0].focus();
          return;
        }
        // Get dom coordinates of focused element
        const { x, y, width, height } = focused.getBoundingClientRect();
        // Find the closest button in the direction of the arrow key
        const closest = buttons.reduce(
          (closest: any, button) => {
            const {
              x: bx,
              y: by,
              width: bw,
              height: bh,
            } = button.getBoundingClientRect();
            let d = Infinity;
            if (e.key === "ArrowUp" && by < y) {
              d = y - by - bh + Math.abs(bx - x);
              if (d < closest.d) {
                closest = { d, button };
              }
            } else if (e.key === "ArrowDown" && by > y) {
              d = by - y - height + Math.abs(bx - x);
              if (d < closest.d) {
                closest = { d, button };
              }
            } else if (e.key === "ArrowLeft" && bx < x) {
              // override for menu
              if (
                button.classList.contains("focused") &&
                !button.classList.contains("no-focus-override")
              ) {
                closest = { d: 0, button };
              } else if (
                button.classList.contains("focused") &&
                button.classList.contains("no-focus-override")
              ) {
                d = x - bx - bw;
                if (d <= closest.d) {
                  closest = { d, button };
                }
              } else {
                d = x - bx - bw + Math.abs(by - y);
                if (d < closest.d) {
                  closest = { d, button };
                }
              }
            } else if (e.key === "ArrowRight" && bx > x) {
              d = bx - x - width + Math.abs(by - y);
              if (d < closest.d) {
                closest = { d, button };
              }
            }
            if (d === closest.d) {
              // If the distance is the same, choose the button that has the focused classname
              if (button.classList.contains("focused")) {
                closest = { d, button };
              }
            }

            return closest;
          },
          { d: Infinity, button: null }
        );
        if (closest.button) {
          closest.button.focus();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [enabled]);
};
