import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import useStore from "../../gameStore";
import foodFightImg from "../../images/food_fight_logo.png";
import { QuestionSet } from "../../interfaces";
import { containerVariants } from "../../motionVariants";
// import { music2, soundEffects, stopAllSound } from "../../sound";
import GameCard from "../GameCard";
import ScoreCard from "../ScoreCard";
import TimedNotification from "../TimedNotification";
import HostBonusStickers from "./HostBonusStickers";
import "./HostPick.css";

const HostPickScreen = (props: any) => {
  const {
    renderedGame: game,
    muteMusic,
    muteSounds,
    questionSets,
  } = useStore();
  const timeout = useRef(0);
  const [isFoodFight, setIsFoodFight] = useState(false);
  useEffect(() => {
    const questionSet: QuestionSet | undefined = questionSets?.find(
      (set) => game?.question_sets?.indexOf(set.id) !== -1
    );
    setIsFoodFight(questionSet?.name === "foodfight");
  }, [game?.question_sets, questionSets]);

  useEffect(() => {
    // stopAllSound();
    if (!game?.last_turn && !muteSounds) {
      // soundEffects.startGame.play();
    }
    if (game?.last_turn?.correct && !muteSounds) {
      timeout.current = window.setTimeout(() => {
        // soundEffects.scoreMeter.play();
      }, 3500); // TODO: these timeouts should be shared more globally
    }
    if (!muteMusic) {
      // music2.play();
    }
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
      // stopAllSound();
    };
  }, [muteMusic]);
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="show"
        className="HostPick-cards"
      >
        {game?.next_questions
          ?.sort((a, b) => a?.question?.generation - b?.question?.generation)
          .map(({ question }) => (
            <GameCard
              key={question?.id}
              className="HostPick-card"
              question={question}
              check={game!.turn?.team?.points_generations.includes(
                question?.generation
              )}
            />
          ))}
      </motion.div>
      <div className="HostPick-scores-section">
        <div className="HostPick-logo-container">
          <h2 className="HostPick-logo">Gensmak!</h2>
          {isFoodFight && (
            <img
              className="HostPick-food-fight-logo"
              src={foodFightImg}
              alt="Food Fight"
            />
          )}
        </div>
        <div className="HostPick-scores">
          {game?.teams?.map((team) => {
            const getsStickers =
              game.last_turn?.correct && game.last_turn.team.id === team.id;
            return (
              <React.Fragment key={team.id}>
                {getsStickers && <HostBonusStickers />}
                <ScoreCard
                  key={team.id}
                  team={team}
                  className={`HostPick-scorecard ${
                    getsStickers ? "HostPick-scorecard-front" : ""
                  }`}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <TimedNotification repeat={true} startHidden={true}>
        <b>{game!.turn?.team?.name}</b> pick a card
        <br /> on your phone.
      </TimedNotification>
    </>
  );
};

export default HostPickScreen;
