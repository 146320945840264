import useStore from "../../gameStore";
import cartIconImg from "../../images/cart_icon.svg";
import playIconImg from "../../images/play_icon.svg";
import { setCookie } from "../../utils";
import MainButton from "../MainButton";
import { Pack } from "./HostExpansionCard";
import "./HostExpansionMenu.css";

const HostExpansionMenu = ({
  pack,
  onClickPlay,
  children,
  vertical = false,
}: {
  pack: Pack;
  onClickPlay?: () => void;
  children?: React.ReactNode;
  vertical?: boolean;
}) => {
  const {
    setShowJoinGame,
    setShowSetupInstructions,
    updateGame,
    liveGame,
    setShowMenu,
  } = useStore();
  const { id, image, title, status, invert, price, description } = pack;
  const clickPlay = () => {
    setCookie("question_set", id);
    updateGame(liveGame!.id!, {
      question_sets: [id],
    });
    setShowSetupInstructions(true);
    setShowJoinGame(true);
    setShowMenu(false);
  };
  const clickBuy = () => {
    setCookie("question_set", id);
    updateGame(liveGame!.id!, {
      question_sets: [id],
    });
    setShowSetupInstructions(true);
    setShowJoinGame(true);
  };
  return (
    <div
      className={`HostExpansionMenu ${vertical ? "vertical" : "horizontal"}`}
    >
      <div
        className={`HostExpansionMenu-pack ${
          status === "coming soon" ? "disabled" : ""
        }`}
      >
        <img src={image} alt={title} />
        <h2 style={{ color: invert ? "#fff" : "#000" }}>{title}</h2>
        {(price || status === "coming soon") && (
          <span className="status">{price || status}</span>
        )}
      </div>
      <div className="HostExpansionMenu-description">
        <h2>{title.toUpperCase()}</h2>
        <p>{description}</p>
        {(status === "owned" || status === "free") && (
          <MainButton
            autoFocus
            onClick={onClickPlay ?? clickPlay}
            className="HostExpansionMenu-icon-button"
          >
            <img src={playIconImg} alt="Play" />
            Play
          </MainButton>
        )}
        {status === "for sale" && (
          <MainButton
            onClick={clickBuy}
            className="HostExpansionMenu-icon-button"
          >
            <img src={cartIconImg} alt="Cart" />
            Buy
          </MainButton>
        )}
        {children}
      </div>
    </div>
  );
};

export default HostExpansionMenu;
