import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import useStore from "../../gameStore";
import boomerImg from "../../images/boomer.svg";
import genXImg from "../../images/genx.svg";
import genZImg from "../../images/genz.svg";
import greatestImg from "../../images/greatest.svg";
import millennialImg from "../../images/millennial.svg";
import smileImg from "../../images/smile.svg";
// import { soundEffects } from "../../sound";
import BonusSticker from "../BonusSticker";
import "./HostBonusStickers.css";
const generationToImage: { [key: number]: string } = {
  1922: greatestImg,
  1946: boomerImg,
  1965: genXImg,
  1981: millennialImg,
  1997: genZImg,
  0: smileImg,
};
const HostBonusStickers = (props: any) => {
  const [show, setShow] = useState(true);
  const [outsideGen, setOutsideGen] = useState(false);
  const { renderedGame: game, muteSounds } = useStore();
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);
  useEffect(() => {
    const teamGens = game?.last_turn?.team?.players?.map(
      (p: any) => p.generation
    );
    setOutsideGen(!teamGens?.includes(game?.last_turn?.generation) || false);
  }, [game?.last_turn]);
  // useEffect(() => {
  //   if (!muteSounds && show) {
  //     setTimeout(() => {
  //       soundEffects.scorePoint.play();
  //     }, 250);
  //     if (!game?.last_turn?.used_hint) {
  //       setTimeout(() => {
  //         soundEffects.scorePoint.play();
  //       }, 500);
  //     }
  //     if (outsideGen) {
  //       setTimeout(() => {
  //         soundEffects.scorePoint.play();
  //       }, 750);
  //     }
  //   }
  // }, [show, outsideGen]);
  return (
    <motion.div className="HostBonusStickers">
      <AnimatePresence>
        {show && (
          <motion.div
            key="HostBonusSticker"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="HostBonusStickers-background"
          />
        )}
        {show && (
          <motion.img
            key="HostBonusSticker-gen"
            className="HostBonusStickers-sticker HostBonusStickers-gen"
            initial={{ scale: 4, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{
              scale: 0,
              opacity: 0,
              transition: { duration: 0.5, delay: 0 },
            }}
            transition={{ type: "spring", delay: 0.25, duration: 0.3 }}
            src={generationToImage[game?.last_turn?.generation || 0]}
          />
        )}
        {show && !game?.last_turn?.used_hint && (
          <BonusSticker
            key="HostBonusSticker-no-hint"
            className="HostBonusStickers-sticker HostBonusSticker-no-hint"
            delay={0.5}
          >
            No hint!
          </BonusSticker>
        )}
        {show && outsideGen && (
          <BonusSticker
            key="HostBonusSticker-outside-gen"
            className="HostBonusStickers-sticker HostBonusSticker-outside-gen"
            delay={0.75}
          >
            Outside your <br />
            generation!
          </BonusSticker>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
export default HostBonusStickers;
