import { AnimatePresence, motion } from "framer-motion";
import {
  CSSProperties,
  ReactFragment,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { slideInVariants } from "../motionVariants";
import "./TimedNotification.css";

// NOTE: this should be used with children OR childrenOptions, not both
// I haven't bothered to make it work with both
const TimedNotification = ({
  children,
  childrenOptions,
  className,
  style: styleOverride,
  duration = 6000,
  hiddenDuration = 8000,
  repeat = false,
  startHidden = false,
  ...rest
}: {
  children: ReactNode | ReactNode[] | string;
  childrenOptions?: (ReactFragment | ReactNode | string)[];
  className?: string;
  style?: CSSProperties;
  duration?: number;
  repeat?: number | boolean;
} & any) => {
  const [show, setShow] = useState(!startHidden);
  const [childIndex, setChildIndex] = useState(0);
  const [child, setChild] = useState(childrenOptions?.[childIndex] || children);
  const [repetitionsLeft, setRepetitionsLeft] = useState(repeat);
  useEffect(() => {
    if (childrenOptions?.length > 0) {
      setChild(childrenOptions[childIndex]);
    } else {
      setChild(children);
    }
  }, [childrenOptions, childIndex, children]);
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
      }, duration);
      return () => clearTimeout(timeout);
    } else if (
      repeat === true ||
      (typeof repeat === "number" && repetitionsLeft > 0)
    ) {
      const timeout = setTimeout(() => {
        if (childrenOptions?.length > 0) {
          setChildIndex((childIndex + 1) % childrenOptions.length);
        }
        if (typeof repeat === "number") {
          setRepetitionsLeft(repetitionsLeft - 1);
        }
        setShow(true);
      }, hiddenDuration);
      return () => clearTimeout(timeout);
    }
  }, [show, duration, hiddenDuration]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          variants={slideInVariants}
          key="TimedNotification"
          initial="hidden"
          animate="show"
          exit="hidden"
          {...rest}
          className={`TimedNotification ${className || ""}`}
          style={styleOverride}
        >
          {child}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TimedNotification;
