import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import useStore from "../../gameStore";
import questionMarkImage from "../../images/question_mark.svg";
// import { music1, soundEffects, stopAllSound } from "../../sound";
import GenTag from "../GenTag";
import SecondaryButton from "../SecondaryButton";
import TimedNotification from "../TimedNotification";
import "./HostJoinScreen.css";

const playerPositions = [
  { top: "0%", left: "60%" },
  { top: "45%", left: "25%" },
  { top: "40%", left: "75%" },
  { top: "70%", left: "50%" },
  { top: "20%", left: "70%" },
  { top: "35%", left: "25%" },
  { top: "60%", left: "30%" },
  { top: "75%", left: "75%" },
];
const HostJoinScreen = () => {
  const {
    renderedGame: game,
    updateGame,
    muteMusic,
    muteSounds,
    setShowSetupInstructions,
  } = useStore();
  const [numPlayers, setNumPlayers] = useState(game!.players!.length || 0);
  const [teamSizeDiff, setTeamSizeDiff] = useState(
    game!.teams![0].players!.length - game!.teams![1].players!.length
  );
  const clickSetupInstructions = () => {
    setShowSetupInstructions(true);
  };

  // useEffect(() => {
  //   if (!muteMusic) {
  //     music1.play();
  //   }
  //   return stopAllSound;
  // }, [muteMusic]);

  useEffect(() => {
    if (!muteSounds) {
      if (numPlayers < game!.players!.length) {
        // soundEffects.joinGame.play();
      } else if (
        teamSizeDiff !==
        game!.teams![0].players!.length - game!.teams![1].players!.length
      ) {
        // soundEffects.switchTeams.play();
      }
      setNumPlayers(game!.players!.length);
      setTeamSizeDiff(
        game!.teams![0].players!.length - game!.teams![1].players!.length
      );
    }
  }, [game!.players!.length, game!.teams, muteSounds]);

  useEffect(() => {
    if (game && game.players && game.players.length >= 12) {
      updateGame(game.id!, {
        started_at: new Date().toISOString(),
      });
    }
  }, [game, updateGame]);

  return (
    <>
      <div className="JoinScreen">
        <div className="JoinScreen-join">
          <h2>
            Let’s play! Everyone grab your phone and scan the code to join.
          </h2>
          <SecondaryButton
            className="JoinScreen-instructions-button"
            onClick={clickSetupInstructions}
            autoFocus
          >
            <img src={questionMarkImage} alt="Instructions" />
            How to play
          </SecondaryButton>
          <div className="JoinScreen-join-card">
            <QRCodeSVG
              value={process.env.REACT_APP_SERVER_URL_SHORT! + game!.code}
              className="JoinScreen-join-qrcode"
            />
            <h3>{process.env.REACT_APP_SERVER_URL_SHORT! + game!.code}</h3>
          </div>
        </div>
        <div className="JoinScreen-teams">
          {game!.teams!.map((team, i) => (
            <div key={team.id} className="JoinScreen-team">
              <h3>{team.name}</h3>
              {team!.players!.length ? (
                team!.players!.map((player, j) => (
                  <GenTag
                    key={player.id}
                    player={player}
                    className="JoinScreen-player"
                    variants={{
                      hidden: { scale: 0, rotate: -10, x: "-50%" },
                      show: { scale: 1, rotate: 0, x: "-50%" },
                    }}
                    style={
                      playerPositions[(j + 4 * i) % playerPositions.length]
                    }
                  />
                ))
              ) : (
                <p className="JoinScreen-no-players">
                  No one has joined from
                  <br /> their phone yet
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <TimedNotification repeat={true}>
        <b>Tip:</b> Everyone controls the <br />
        game using their phones!
      </TimedNotification>
    </>
  );
};

export default HostJoinScreen;
