import { motion } from "framer-motion";
import { useEffect } from "react";
import useStore from "../../gameStore";
import smileImg from "../../images/smile.svg";
import starImg from "../../images/star.svg";
import { itemVariants } from "../../motionVariants";
import "./PlayerScreenPlayWait.css";

const PlayerScreenPlayWait = () => {
  const { renderedGame: game, player, updatePlayer } = useStore();

  useEffect(() => {
    if (player?.id) {
      updatePlayer(player.id, { last_active: new Date().toISOString() });
    }
  }, []);

  return (
    <>
      <div className="PlayerScreenPlayWait-center">
        <motion.img
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", duration: 0.1 }}
          className="PlayerScreenPlayWait-star-img"
          src={starImg}
          alt="star"
        />
        <motion.h2
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", duration: 0.1 }}
          className="PlayerScreenPlayWait-hang"
        >
          Hang
          <br />
          tight!
        </motion.h2>
        <motion.img
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", duration: 0.1 }}
          className="PlayerScreenPlayWait-smile-img"
          src={smileImg}
          alt="smile"
        />
      </div>

      {game?.last_turn && (
        <motion.div
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", duration: 0.1 }}
          className={
            "PlayerScreenPlayWait-last PlayerScreenPlayWait-last-" +
            (game!.last_turn!.correct ? "correct" : "incorrect")
          }
        >
          <h3 className="PlayerScreenPlayWait-last-text">
            Last turn was
            <br />
            {game!.last_turn!.correct ? "CORRECT" : "INCORRECT"}!
          </h3>
          <p>
            The answer was: <br />
            {game!.last_turn!.question!.answer}
          </p>
        </motion.div>
      )}
    </>
  );
};

export default PlayerScreenPlayWait;
