import { useState } from "react";
import useStore from "../../gameStore";
import MainButton from "../MainButton";
import MarqueeRainbow from "../MarqueeRainbow";
import ScoreCard from "../ScoreCard";
import SecondaryButton from "../SecondaryButton";
import "./PlayerScreenEnd.css";

const PlayerScreenEnd = () => {
  const { renderedGame: game, createGame } = useStore();
  const [scoreBoard, setScoreBoard] = useState<boolean>(false);

  const newGame = () => {
    createGame({ code: "_", last_game: game!.id! });
  };
  if (scoreBoard) {
    return (
      <div className="PlayerScreenEnd-score">
        {game?.teams?.map((team) => (
          <ScoreCard key={team.id} team={team} />
        ))}
        <SecondaryButton
          className="PlayerScreenEnd-score-back"
          onClick={() => setScoreBoard(false)}
        >
          Back
        </SecondaryButton>
      </div>
    );
  } else {
    return (
      <div className="PlayerScreenEnd">
        <MarqueeRainbow>
          {`${game!.teams!.find((o) => o.id === game!.winner)?.name} WINS!`}
        </MarqueeRainbow>
        <div className="PlayerScreenEnd-buttons">
          <MainButton onClick={newGame}>Play again</MainButton>
          <MainButton onClick={() => setScoreBoard(true)}>
            View scoreboard
          </MainButton>
          {/*  <MainButton>
            TODO: do we need a feedback link 
            <a href="https://gensmak.com/">Give feedback</a>
          </MainButton>*/}
        </div>
      </div>
    );
  }
};
export default PlayerScreenEnd;
