import { useEffect, useRef } from "react";
import useStore from "../gameStore";
import menu from "../images/menu.svg";
import "./GameMenuButton.css";

const GameMenuButton = () => {
  const { setShowMenu } = useStore();
  const buttonRef = useRef<HTMLButtonElement>(null);
  // If nothing else is focused, focus the button
  // we dont want to use autoFocus because on some screens
  // we want to autoFocus on a different element
  useEffect(() => {
    if (!document.activeElement || document.activeElement === document.body) {
      buttonRef.current?.focus();
    }
  }, [buttonRef]);

  return (
    <button
      ref={buttonRef}
      className="GameMenuButton"
      onClick={() => setShowMenu(true)}
    >
      <img src={menu} alt="show-menu" />
    </button>
  );
};
export default GameMenuButton;
