import ReactGA from "react-ga4";
var lastEvent = "";

export function gtag(action: string, gameId: string | undefined) {
  if (lastEvent === action + gameId) return;
  lastEvent = action + gameId;
  console.log(action);
  ReactGA.event({
    category: "event",
    action: action,
    label: JSON.stringify({
      platform: getCookie("app") ?? "web",
      gameId: gameId,
    }),
  });
}

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(
  name: string,
  value: string,
  days: number | null = 30
) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
