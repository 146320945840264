import { motion } from "framer-motion";
import React from "react";
import useStore from "../../gameStore";
import checkBoomer from "../../images/checkboomer.svg";
import checkGenX from "../../images/checkgenx.svg";
import checkGenZ from "../../images/checkgenz.svg";
import checkGreatest from "../../images/checkgreatest.svg";
import checkMillennial from "../../images/checkmillennial.svg";
import { containerVariants } from "../../motionVariants";
import { useSlideProgress } from "../../slideController";
// import { stopAllSound, stopAllTutorial, tutorialSounds } from "../../sound";
import BonusSticker from "../BonusSticker";
import GameCard from "../GameCard";
import InstructionButtons from "../InstructionButtons";
import "./HostInstructions.css";
const defaults = {
  question: "",
  answer: "",
  hint: "",
};
// TODO: this is duplicate, make gen asset map in one file
const checkImages: { [key: number]: string } = {
  1922: checkGreatest,
  1946: checkBoomer,
  1965: checkGenX,
  1981: checkMillennial,
  1997: checkGenZ,
};
const stickerVariants = Array(3)
  .fill("")
  .map(() => ({
    hidden: { scale: 4, rotate: Math.random() * 60 - 30, opacity: 0 },
    show: { scale: 1, rotate: Math.random() * 60 - 30, opacity: 1 },
  }));

export const itemVariants = {
  hidden: { scale: 0, rotate: -10 },
  show: { scale: 1, rotate: 0 },
};
const cards = [
  {
    generation: 1922,
    difficulty: 1,
    category: "Pop \nCulture",
    rotation: "2deg",
  },
  {
    generation: 1946,
    difficulty: 2,
    category: "People \n& Events",
    rotation: "-7deg",
  },
  {
    generation: 1965,
    difficulty: 1,
    category: "Shows \n& Films",
    rotation: "-9deg",
  },
  { generation: 1981, difficulty: 3, category: "Music", rotation: "-6deg" },
  {
    generation: 1997,
    difficulty: 2,
    category: "Pop \nCulture",
    rotation: "10deg",
  },
];

const HostInstructions = () => {
  const { slideNumber, muteSounds } = useStore();
  const { slideProgress } = useSlideProgress();
  // useEffect(() => {
  //   stopAllSound();
  //   stopAllTutorial();
  //   if (!muteSounds && tutorialSounds[Math.max(slideNumber, 0)]) {
  //     tutorialSounds[Math.max(slideNumber, 0)].seek(0);
  //     tutorialSounds[Math.max(slideNumber, 0)].play();
  //   }
  //   return stopAllSound;
  // }, [slideNumber, muteSounds]);
  const cardElements = (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className={`HostInstructions-cards ${
        slideNumber === 0 ? "collapsed" : ""
      }`}
    >
      {cards.map((question, i) => (
        <div
          key={`instructions-card-${i}`}
          className="HostInstructions-card-wrapper"
          style={
            {
              "--card-rotate": question?.rotation,
              "--card-delay": `${i * 0.08}s`,
            } as React.CSSProperties
          }
        >
          <GameCard
            className="HostInstructions-card"
            question={{ ...question, id: `${i}`, ...defaults }}
          />
        </div>
      ))}
    </motion.div>
  );
  const slides = [
    <>
      {cardElements}
      <h3>
        Welcome to <i>GENSMAK!</i> This is a quick tutorial on how to play.
        <br /> And the rest, you’ll figure it out during the game.
      </h3>
    </>,
    <>
      {cardElements}
      <h3>
        Teams take turns choosing a trivia card to answer
        <br /> from each of these five generation decks. Choose wisely!
      </h3>
    </>,
    <>
      <div className="HostInstructions-stickers">
        <BonusSticker variants={stickerVariants[0]} delay={0.5}>
          Correct <br /> answer!
        </BonusSticker>
        <BonusSticker variants={stickerVariants[1]} delay={1}>
          Outside team's <br /> generations
        </BonusSticker>
        <BonusSticker variants={stickerVariants[2]} delay={1.5}>
          No hint
        </BonusSticker>
      </div>
      <h3>
        You earn points for every correct answer, for questions outside
        <br /> of your team’s generations, and for not using a hint.
      </h3>
    </>,
    <>
      <div className="HostInstructions-cards-points">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          key="instructions-cards-end"
          className="HostInstructions-cards"
        >
          {cards.map((question, i) => (
            <motion.div
              key={`instructions-card-${i}`}
              className="HostInstructions-card-wrapper"
              variants={itemVariants}
              initial="hidden"
              animate="show"
            >
              <img
                src={checkImages[question?.generation]}
                className="HostInstructions-check"
                alt="check"
              />
              <GameCard
                className="HostInstructions-card"
                question={{ ...question, id: `${i}`, ...defaults }}
              />
            </motion.div>
          ))}
        </motion.div>
        <motion.p
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", delay: 0.5 }}
        >
          +
        </motion.p>
        <BonusSticker
          key="instructions-sticker-end"
          variants={itemVariants}
          delay={0.75}
          label="15"
        >
          Points
        </BonusSticker>
      </div>
      <h3>
        The first team to answer a question from every generation,
        <br /> and earns 15 points will be the winners. Good luck!
      </h3>
    </>,
  ];
  return (
    <div className="HostInstructions">
      <div className="HostInstructions-time-bars">
        {slides.map((slide, i) => (
          <div key={i} className="HostInstructions-time-bar">
            <div
              className={"HostInstructions-time-bar-fill"}
              style={{
                width:
                  i === slideNumber
                    ? `${slideProgress * 100}%`
                    : i < slideNumber
                    ? "100%"
                    : "0%",
              }}
            ></div>
          </div>
        ))}
      </div>
      {slides[Math.max(slideNumber, 0)]}
      <InstructionButtons className="HostInstructions-buttons" />
    </div>
  );
};
export default HostInstructions;
